import { useQuery } from "./useQuery";
import { useCwcChat } from "./useCwcChat";
import { useSender } from "./useSender";
import log from "loglevel";
import { useEffect, useState } from "react";
import sha256 from "crypto-js/sha256";
import { useCustomer } from "./useCustomer";

const BOT_API_PATH =
  window?.["app_env"].REACT_APP_BOT_API_URL || "http://localhost:5010/test_api";

export function useCwcIframe() {
  const {
    sendMessage,
    toggleWindowState,
    setChatWindowOpen,
    changeSession,
    customerData,
    user,
    ready,
    options: _options,
    startSolution,
    segment,
    setSegment,
    cartDevicesInsurance,
    cartLocked,
    hasWelcomeMessage
  } = useCwcChat();

  const sender = useSender();
  const query = useQuery();
  const origin = query.get("origin");
  const sessionId = query.get("sessionId");
  const [options, setOptions] = useState(_options);
  const [parseCustomer, setCustomer] = useCustomer();
  const [pendingEvent, setPendingEvent] = useState(null);
  const [readyWasSent, setReadyWasSent] = useState(false);

  const [dialogTriggered, setDialogTriggered] = useState(false);

  const defaultLabel =
    options?.default_label === "None"
      ? options?.default_label
      : JSON.parse(options?.default_label || "{}");

  function handleMessage(e) {
    let protection_was_used = false;
    const unknownSegmentProtection = (segmentToUpdate=undefined) => {
      protection_was_used = true;
      if (segment === "unknown") {
        setPendingEvent(e);
        setSegment(segmentToUpdate || "private");
        return false;
      }
      return true;
    };
    const { event, args } = e.data;
    if (event === "chat_window_state_changed") {
      toggleWindowState(args.isWindowOpen);
      return;
    }
    if (event === "send" && unknownSegmentProtection(args.segment)) {
      sender.focus();
      sendMessage(args);
      return;
    }
    if (event === "setCustomer") {
      changeSession({
        ...args,
      });
      return;
    }
    if (event === "setOptions") {
      setOptions((current) => {
        return {
          ...current,
          ...args,
        };
      });
      return;
    }
    if (event === "startSolution" && unknownSegmentProtection(args.segment)) {
      sender.focus();
      startSolution(args);
      return;
    }
    if (event === "cartDevicesInsurance" && unknownSegmentProtection()) {
      sender.focus();
      cartDevicesInsurance(args);
      return;
    }
    if (event === "cartLocked" && unknownSegmentProtection()) {
      sender.focus();
      cartLocked(args);
      return;
    }
    if (event === "windowResize" || protection_was_used) {
      return; // known message
    }
    log.warn("Unknown message received", e.data);
  }

  useEffect(() => {
    window.parent.postMessage({ sessionId, event: "hasWelcomeMessageChange", args: { hasWelcomeMessage } },
      origin);
    console.log("hasWelcomeMessageChange", hasWelcomeMessage);
  }, [hasWelcomeMessage]);

  useEffect(() => {
    if (segment !== "unknown" && pendingEvent) {
      handleMessage(pendingEvent);
      setPendingEvent(null);
    }
  }, [segment, !!pendingEvent]);

  useEffect(() => {
    // if (!ready) return;
    if (!origin) return;

    window.addEventListener("message", handleMessage, false);
    if (!readyWasSent) {
      window.parent.postMessage(
        { sessionId, event: "ready", args: { customer: customerData, user } },
        origin
      );
      setReadyWasSent(true);
    }

    if (!user?.user_id || dialogTriggered || defaultLabel === "None") return;

    setDialogTriggered(true);
    const customer = parseCustomer();
    if (
      (options?.cachingEnabled && customer?.default_dialogue_started) ||
      !defaultLabel?.label
    )
      return;

    const data = {
      chat_session_uid: user?.user_id,
      dialogue_code: "automated-dialog",
      context_data: defaultLabel.label,
      default_label_language: options?.default_label_language || "",
    };
    const hashedKeys = [
      "chat_session_uid",
      "dialogue_code",
      "context_data",
      "force",
      "trigger_type",
    ];
    const SALT = "8b490009910f4cdb9b9251257132ae7y";
    const concatenated_string =
      hashedKeys.map((key) => data[key] || "").join("") + SALT;
    const hash = sha256(concatenated_string).toString();

    fetch(`${BOT_API_PATH}/start_dialogue`, {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        ...data,
        hash,
      }),
    });

    customer.default_dialogue_started = true;
    if (user?.user_id !== customer?.chat_session_uid) {
      customer.dialogIsOpen = false;
    }
    setCustomer({
      ...customer,
      chat_session_uid: user?.user_id || customer?.chat_session_uid,
    });

    setChatWindowOpen(!!customer.dialogIsOpen);
  }, [ready, origin]);

  useEffect(() => {
    if (!ready) return;
    if (!origin) return;

    window.parent.postMessage({ sessionId, event: "session_update" }, origin);
  }, [ready, origin, customerData.hash]);

  function copyToClipboard(text) {
    window.parent.postMessage(
      { sessionId, event: "clipboard_write", args: { text } },
      origin
    );
  }
  function toggleParentWindow(state = false) {
    window.parent.postMessage(
      { sessionId, event: state ? "open_window" : "close_window", args: {} },
      origin
    );
    const customer = parseCustomer();
    customer.dialogIsOpen = !!state;
    setCustomer(customer);
    // setChatWindowOpen(state);
  }

  function sendOptions(options) {
    window.parent.postMessage(
      { sessionId, event: "set_options", args: { options } },
      origin
    );
  }

  function setNotSeenMessageCount(count, messages) {
    window.parent.postMessage(
      {
        sessionId,
        event: "set_not_seen_messages_count",
        args: { count, messages },
      },
      origin
    );
  }

  function displayImage(imageUrl) {
    window.parent.postMessage(
      { sessionId, event: "display_image", args: { imageUrl } },
      origin
    );
  }
  if (!options.cachingEnabled && parseCustomer()?.dialogIsOpen) {
    setCustomer({
      ...parseCustomer(),
      dialogIsOpen: false,
    });
  }
  return {
    copyToClipboard,
    toggleParentWindow,
    displayImage,
    sendOptions,
    setNotSeenMessageCount,
    inIframe: !!origin,
    options,
    dialogIsOpen: options.cachingEnabled
      ? parseCustomer()?.dialogIsOpen
      : false,
  };
}
